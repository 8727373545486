<template>
	<div class="home">
		<a-breadcrumb>
			<a-breadcrumb-item href="">
				<a-icon type="home" />
			</a-breadcrumb-item>
			<a-breadcrumb-item href="">
				<a-icon type="credit-card" />
				<span>交易</span>
			</a-breadcrumb-item>
			<a-breadcrumb-item> 实时账务 </a-breadcrumb-item>
		</a-breadcrumb>

		<div class="search-list-box">
			<a-form class="ant-advanced-search-form">
				<a-row :gutter="24">
					<a-col :span="4">
						<a-form-item :label="`设备编号：`">
							<a-input placeholder="请输入设备编号" v-model="searchData.number" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-form-item :label="`设备名称：`">
							<a-input placeholder="请输入设备名称" v-model="searchData.name" />
						</a-form-item>
					</a-col>
					<a-col :span="4">
						<a-button type="primary" @click="getList"> 查询 </a-button>
						<a-button :style="{ marginLeft: '8px' }" @click="handleReset">
							重置
						</a-button>
					</a-col>
				</a-row>
			</a-form>
			<div class="search-result-list">
				<div class="table-header-box">
					<div class="left">
						<h3 class="title">实时账务</h3>
					</div>
					<div class="right">
						<a-button type="primary" class="marginr20" @click="showDetail">
							详情
						</a-button>
					</div>
				</div>
				<a-table :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :columns="columns" @change="handleTableChange"
				 :pagination="{total: total_page * 10}" :data-source="list" :scroll="{ x: 1600 }" rowKey="id" size="small">
				</a-table>
			</div>
		</div>


		<a-modal v-model="detailShow" width="800px" title="详细信息" :footer="null">
			<a-spin :spinning="detailLoading">
				<a-form-model ref="ruleForm" :model="info" :rules="rules" :label-col="{ span: 7 }" :wrapper-col="{ span: 16 }" style="margin: 0 auto; width: 90%">
				<a-row :gutter="24">
					<a-col :span="12">
						<a-row :gutter="24">
							<a-col :span="24">
								<a-form-model-item label="设备编号：">
									<a-input v-model="info.number" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="设备名称：">
									<a-input v-model="info.name" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="设备类型：">
									<a-input v-model="info.type_name" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="所属银行：">
									<a-input v-model="info.bank_name" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="所属网点：">
									<a-input v-model="info.node_name" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="加币金额(元)：">
									<a-input v-model="info.add_amount" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="微信出账(元)：">
									<a-input v-model="info.wx_outamount" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="微信入账(元)：">
									<a-input v-model="info.wx_inamount" :disabled="true"/>
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-col>
					<a-col :span="12">
						<a-row :gutter="24">
							<a-col :span="24">
								<a-form-model-item label="回收1元(枚)：">
									<a-input v-model="info.in100" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="回收5角(枚)：">
									<a-input v-model="info.in50" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="回收1角(枚)：">
									<a-input v-model="info.in10" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="剩余1元(枚)：">
									<a-input v-model="info.out100" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="剩余5角(枚)：">
									<a-input v-model="info.out50" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="剩余1角(枚)：">
									<a-input v-model="info.out10" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="支付宝出账(元)：">
									<a-input v-model="info.ali_outamount" :disabled="true"/>
								</a-form-model-item>
							</a-col>
							<a-col :span="24">
								<a-form-model-item label="支付宝入账(元)：">
									<a-input v-model="info.ali_inamount" :disabled="true"/>
								</a-form-model-item>
							</a-col>
						</a-row>
					</a-col>					
				</a-row>
				</a-form-model>
				<div class="model-footer">
					<div class="left">
						<a-button type="primary" class="marginr20" html-type="submit" @click="detailShow = false">
							取消
						</a-button>
					</div>
					<div class="right">
						<a-button type="dashed" :disabled="isEditBtn('prev')" @click="editToData('prev')" class="" html-type="submit">
							上一条
						</a-button>
						<a-button type="dashed" :disabled="isEditBtn('next')" @click="editToData('next')" class="marginr20" html-type="submit">
							下一条
						</a-button>
					</div>
				</div>
			</a-spin>
		</a-modal>
	</div>
</template>

<script>
	import moment from 'moment';
	import http from '../http'

	const columns = [{
			title: "设备编号",
			dataIndex: "number",
			width:130,
		},
		{
			title: "所属区域",
			dataIndex: "area",
			width:100,
		},
		{
			title: "上次加币总金额(元)",
			dataIndex: "add_amount",
			width:140,
			align: 'center'
		},
		{
			title: "回收1元(枚)",
			dataIndex: "in100",
			width:100,
			align: 'center'
		},
		{
			title: "回收5角(枚)",
			dataIndex: "in50",
			width:100,
			align: 'center'
		},
		{
			title: "回收1角(枚)",
			dataIndex: "in10",
			width:100,
			align: 'center'
		},
		{
			title: "剩余1元(枚)",
			dataIndex: "out100",
			width:100,
			align: 'center'
		},
		{
			title: "剩余5角(枚)",
			dataIndex: "out50",
			width:100,
			align: 'center'
		},
		{
			title: "剩余1角(枚)",
			dataIndex: "out10",
			width:100,
			align: 'center'
		},
		{
			title: "微信入账(元)",
			dataIndex: "wx_inamount",
			width:120,
			align: 'center'
		},
		{
			title: "微信出账(元)",
			dataIndex: "wx_outamount",
			width:120,
			align: 'center'
		},
		{
			title: "支付宝入账(元)",
			dataIndex: "ali_inamount",
			width:120,
			align: 'center'
		},
		{
			title: "支付宝出账(元)",
			dataIndex: "ali_outamount",
			width:120,
			align: 'center'
		},
		{
			title: "未清机天数",
			dataIndex: "noclear_date",
			align: 'center'
		},
	];

	export default {
		data() {
			return {
				expand: false,
				columns,

				info: {
					name: '',
					number:'',
				},
				detailShow: false,

				searchData: {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					number:'',
					name:'',
				},
				list: [],
				total_page: 0,

				// 表格选择的数据, 存储的是id
				selectedRowKeys: [],
				// 存储的是数据
				selectedRowDataList: [],
				// 当前是第几条编辑信息
				editIndex: 0,

				detailLoading: false,
				
				detailList: [],
				total_detail_page: 0,
				
				
				bankTreeData: [],
				outletsList: [],
			};
		},
		mounted() {
			this.getList()
		},
		created () {
			this.getBankTreeData()
			this.getOutletsList()
		},
		methods: {
			moment,
			handleReset() {
				this.searchData = {
					page: 1,
					node_id: undefined,
					bank_id: undefined,
					number:'',
					name:'',
				}
				this.getList();
			},
			// 表格分页
			handleTableChange(pagination, filters, sorter) {
				this.searchData.page = pagination.current
				this.getList();
			},
			// 表格选择
			onSelectChange(selectedRowKeys) {
				this.selectedRowKeys = selectedRowKeys;
			},
			showDetail() {
				// 判断是否已经选择数据
				if (this.selectedRowKeys.length == 0) {
					this.$message.warning('请先选择数据');
					return
				}
				let selectDatas = []
				this.list.forEach((v) => {
					if (this.selectedRowKeys.includes(v.id)) {
						selectDatas.push(v)
					}
				})
				this.selectedRowDataList = JSON.parse(JSON.stringify(selectDatas))
				this.editIndex = 0
				// 获取文章详情
				this.getDetail()
				this.detailShow = true
			},
			// 详情
			getDetail() {
				this.detailLoading = true
				http({
					method: 'post',
					url: '/api.transaction.accdetail/',
					data: {
						id: this.selectedRowDataList[this.editIndex].id
					}
				}).then((res) => {
					this.info = res.data
					console.log(this.info)
					this.detailLoading = false
				})
			},
			// 点击上一条 或者 下一条、
			editToData(type) {
				let index = -1
				if (type == 'prev') {
					index = this.editIndex - 1
					if (index == 0) {
						index = 0
					}
				} else if (type == 'next') {
					index = this.editIndex + 1
				}
				this.editIndex = index
				this.getDetail()
			},
			// 判断是否可以点击上一条 或者 下一条、
			isEditBtn(type) {
				if (type == 'prev') {
					if (this.editIndex == 0) {
						return true
					}
					if (this.selectedRowDataList == 1) {
						return true
					}
					if (this.editIndex >= 1) {
						return false
					}
				} else if (type == 'next') {
					if ((this.selectedRowDataList.length - 1) == this.editIndex) {
						return true
					}
				}
			},
			// 列表
			getList() {
				http({
					method: 'post',
					url: '/api.transaction.account/',
					data: {
						...this.searchData,
						bank_id: this.searchData.bank_id || 0,
						node_id: this.searchData.node_id || 0,
					}
				}).then((res) => {
					this.list = res.data.list
					this.total_page = res.data.total_page
				})
			},
			// 日期解析
			handleChangeDate(date) {
				this.searchData.start_date = this.moment(date[0]).format('YYYY-MM-DD')
				this.searchData.end_date = this.moment(date[1]).format('YYYY-MM-DD')
			},
			// 获取银行类型
			getBankTreeData() {
				http({
					method: 'post',
					url: '/api.bank.list/',
					data: {
						page: 1,
						parent_id: 0
					}
				}).then((res) => {
					this.bankTreeData = this.dataDealwith(res.data)
				})
			},
			// 获取网点
			getOutletsList() {
				http({
					method: 'post',
					url: '/api.bank.node.list/',
					data: {
						page: 1,
						name: '',
						bank_id: 0
					}
				}).then((res) => {
					this.outletsList = res.data.list
				})
			},
			dataDealwith(treeData) {
				function setGrayNode(data) { //遍历树  获取id数组
					for (var i = 0; i < data.length; i++) {
						data[i] = {
							value: data[i].bank_id,
							key: data[i].bank_id,
							title: data[i].name,
							children: data[i].list || []
						} //子节点传给另一个函数专门用来改数据
						if (data[i].children && data[i].children.length) { // 如果当前节点有子节点，就递归调用一次
							setGrayNode(data[i].children);
						}
					}
				}
				setGrayNode(treeData)
				return treeData;
			},
		},
	};
</script>
<style>
	.ant-advanced-search-form {
		padding: 24px;
		background: #fbfbfb;
		border: 1px solid #d9d9d9;
		border-radius: 6px;
	}

	.ant-advanced-search-form .ant-form-item {
		display: flex;
	}

	.ant-advanced-search-form .ant-form-item-control-wrapper {
		flex: 1;
	}

	.search-list-box .ant-form {
		max-width: none;
	}

	.search-list-box .search-result-list {
		margin-top: 16px;
		border: 1px dashed #e9e9e9;
		border-radius: 6px;
		background-color: #fafafa;
		min-height: 200px;
		text-align: center;
		padding-top: 80px;
	}

	.imgs {
		display: flex;
	}

	.imgs img {
		width: 140px;
		height: 200px;
	}

	.detail-div{
		line-height:40px;
		color: rgba(0, 0, 0, 0.85);
	}
</style>
